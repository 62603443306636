import { Certification, UploadedFile } from "@prisma/client";
import React, { PropsWithChildren, useEffect, useState } from "react";
import useUser from "../../hooks/useUser";
import { trpc } from "../../utils/trcp";

type attachmentsContext = {
  certifications?: (Certification & { attachments: UploadedFile[] })[];
  refetch: () => void;
  documentationSent?: boolean;
};

const AttachmentsContext = React.createContext<attachmentsContext | undefined>(
  undefined
);

export default AttachmentsContext;

export function AttachmentsContextProvider({ children }: PropsWithChildren) {
  const [sent, setSent] = useState<boolean>();
  const { user } = useUser();

  const { data: certifications } =
    trpc.currentUser.certifications.getAll.useQuery(undefined, {
      enabled: !!user,
    });

  const trpcContext = trpc.useContext();
  function refetch() {
    trpcContext.currentUser.certifications.getAll.invalidate();
  }

  useEffect(() => {
    certifications &&
      setSent(
        certifications.some((c) =>
          c.attachments.some((a) => a.title === "ID_FRONT")
        ) &&
          certifications.some((c) =>
            c.attachments.some((a) => a.title === "ID_BACK")
          ) &&
          certifications.some((c) =>
            c.attachments.some((a) => a.title === "AGREEMENT")
          )
      );
  }, [certifications]);

  return (
    <AttachmentsContext.Provider
      value={{
        certifications,
        refetch: refetch,
        documentationSent: sent,
      }}
    >
      {children}
    </AttachmentsContext.Provider>
  );
}
