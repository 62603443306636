/* This example requires Tailwind CSS v2.0+ */
import {
  faRightFromBracket,
  faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import { signOut } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import paths from "../../config/paths";
import useUser, { permissions } from "../../hooks/useUser";
import logo from "../../public/cru-logo.png";
import isGranted from "../../server/security/IsGranted";
import Button from "../forms/Button";
import UserProfileMenu from "./UserProfileMenu";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const router = useRouter();
  const { user } = useUser();

  return (
    <Disclosure
      as="header"
      className="grow-0 border-b-2 border-primary bg-white"
    >
      {({ open }) => (
        <>
          <div className="container">
            <div className="flex justify-between h-16 sm:h-20">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Link href={paths.home}>
                    <Image
                      className="h-8 sm:h-10 w-auto"
                      src={logo}
                      alt="Cruz roja Uruguaya"
                    />
                  </Link>
                </div>
                <div className="hidden md:flex ml-12 lg:space-x-12 md:space-x-6">
                  {!user && (
                    <Link
                      href={paths.register}
                      className={classNames(
                        "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium",
                        paths.register === router.pathname
                          ? "border-primary"
                          : "border-transparent hover:border-primary hover:text-gray-700"
                      )}
                    >
                      Registro de voluntarios
                    </Link>
                  )}
                  <Link
                    href={paths.update}
                    className={classNames(
                      "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium",
                      paths.profile === router.pathname
                        ? "border-primary"
                        : "border-transparent hover:border-primary hover:text-gray-700"
                    )}
                  >
                    Actualización de datos
                  </Link>
                  {user && (
                    <>
                      <Link
                        href={paths.certifications}
                        className={classNames(
                          "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium",
                          paths.certifications === router.pathname
                            ? "border-primary"
                            : "border-transparent hover:border-primary hover:text-gray-700"
                        )}
                      >
                        Documentación
                      </Link>
                      <Link
                        href={paths.health}
                        className={classNames(
                          "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium",
                          paths.health === router.pathname
                            ? "border-primary"
                            : "border-transparent hover:border-primary hover:text-gray-700"
                        )}
                      >
                        Ficha médica
                      </Link>
                    </>
                  )}
                  <Link
                    href={paths.volunteering}
                    className={classNames(
                      "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium",
                      paths.volunteering === router.pathname
                        ? "border-primary"
                        : "border-transparent hover:border-primary hover:text-gray-700"
                    )}
                  >
                    Voluntariado
                  </Link>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/* <button
                  type="button"
                  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                {user ? (
                  <UserProfileMenu />
                ) : (
                  <div className="text-sm">
                    <Link href={paths.login} className="px-4 py-2">
                      Acceder
                    </Link>
                    <Link
                      href={paths.register}
                      className="bg-primary hover:bg-cru-400 active:bg-cru-400 rounded-full  text-white px-4 py-2"
                    >
                      Registro
                    </Link>
                  </div>
                )}
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <FontAwesomeIcon
                      icon={faClose}
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faBars}
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden absolute bg-white w-full shadow-md">
            <div className="pt-2 pb-3 space-y-1">
              {/* Current: "bg-cru-50 border-primary text-primary-dark", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}

              <Link href={paths.home}>
                <Disclosure.Button
                  as="div"
                  className="bg-cru-50 border-primary text-primary-dark block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Inicio
                </Disclosure.Button>
              </Link>
              <Link href={paths.update}>
                <Disclosure.Button
                  as="div"
                  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Actualización de datos
                </Disclosure.Button>
              </Link>
              <Link href={paths.certifications}>
                <Disclosure.Button
                  as="div"
                  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Documentación
                </Disclosure.Button>
              </Link>

              <Link href={paths.health}>
                <Disclosure.Button
                  as="div"
                  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Ficha médica
                </Disclosure.Button>
              </Link>
              <Link href={paths.volunteering}>
                <Disclosure.Button
                  as="div"
                  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  Voluntariado
                </Disclosure.Button>
              </Link>
              {user ? (
                <button
                  onClick={() => signOut({ callbackUrl: "/" })}
                  className="border-transparent  text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    className="mr-2 h-4"
                  />{" "}
                  <span>Cerrar sesión</span>
                </button>
              ) : (
                <Link
                  href={paths.login}
                  className="border-transparent  text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  <FontAwesomeIcon
                    icon={faRightToBracket}
                    className="mr-2 h-4"
                  />{" "}
                  <span>Iniciar sesión</span>
                </Link>
              )}
            </div>

            {isGranted(user, permissions.access_dashboard) && (
              <div className="p-4">
                <Link
                  href={
                    isGranted(user, permissions.manage_volunteers)
                      ? paths.admin_volunteers
                      : paths.admin_staff
                  }
                >
                  <Button className="w-full" color="light">
                    ADMINISTRADOR
                  </Button>
                </Link>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
